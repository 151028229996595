import { supportsPassiveEvents } from 'detect-passive-events';
import { throttle } from 'throttle-debounce';

export default class Header {
    static id = 'header';

    constructor(node) {
        this.node = node;
        this.toggleButtonNode = this.node.querySelector('[data-ref="toggle"]');
        this.backToTopNode = this.node.querySelector('[data-ref="back-to-top"]');

        this.showBackgroundAfterNode = this.node.dataset.showBackgroundAfter
            ? document.querySelector(this.node.dataset.showBackgroundAfter)
            : null;
        this.showLogoAfterNode = this.node.dataset.showLogoAfter
            ? document.querySelector(this.node.dataset.showLogoAfter)
            : null;

        this.toggleButtonNode.addEventListener('click', this.handleToggleButtonClick.bind(this));
        window.addEventListener('thishere:nav:closed', this.handleNavClosed.bind(this));
        window.addEventListener('thishere:nav:opened', this.handleNavOpened.bind(this));
        document.addEventListener(
            'scroll',
            throttle(16, this.handleScroll.bind(this)),
            supportsPassiveEvents ? { passive: true } : false,
        );
    }

    handleNavClosed() {
        this.toggleButtonNode.setAttribute('aria-expanded', 'false');
    }

    handleNavOpened() {
        this.toggleButtonNode.setAttribute('aria-expanded', 'true');
    }

    handleToggleButtonClick() {
        this.node.dispatchEvent(
            new CustomEvent(
                this.toggleButtonNode.getAttribute('aria-expanded') === 'false'
                    ? 'thishere:nav:open'
                    : 'thishere:nav:close',
                { bubbles: true },
            ),
        );
    }

    handleScroll() {
        if (this.showBackgroundAfterNode) {
            const { bottom } = this.showBackgroundAfterNode.getBoundingClientRect();
            this.node.classList.toggle('has-background', bottom <= this.node.clientHeight);
        }

        if (this.showLogoAfterNode) {
            const { bottom } = this.showLogoAfterNode.getBoundingClientRect();
            this.node.classList.toggle('has-logo', bottom <= this.node.clientHeight);
        }

        this.backToTopNode.classList.toggle(
            'is-visible',
            window.scrollY > document.documentElement.clientHeight,
        );
    }
}
