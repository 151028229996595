import { FETCH_OPTS } from '@lib/constants';

export default class CaseStudyFilter {
    static id = 'case-study-filter';

    constructor(node) {
        this.node = node;
        this.linkNodes = this.node.querySelectorAll('[data-ref="link"]');

        this.ajaxTarget = this.node.dataset.ajaxTarget;
        this.ajaxTargetNode = this.ajaxTarget ? document.querySelector(this.ajaxTarget) : null;
        if (!this.ajaxTargetNode) {
            throw new Error(`Ajax target node matching selector '${this.ajaxTarget}' not found`);
        }

        this.node.addEventListener('click', this.handleClick.bind(this));
        window.addEventListener('popstate', this.handlePopState.bind(this));
    }

    updateFilter(categorySlug = '', pushState = true) {
        this.linkNodes.forEach((linkNode) => linkNode.removeAttribute('aria-current'));
        const nextActiveLinkNode = [...this.linkNodes].find(
            (linkNode) => linkNode.dataset.categorySlug === categorySlug,
        );
        nextActiveLinkNode.setAttribute('aria-current', 'page');
        const url = nextActiveLinkNode.href;
        if (pushState) window.history.pushState({ categorySlug }, '', url);
        this.fetchResults(url);
    }

    handleClick(event) {
        if (event.target.closest('[data-ref="link"]')) {
            this.handleLinkClick(event);
        }
    }

    handleLinkClick(event) {
        event.preventDefault();
        const linkNode = event.target.closest('[data-ref="link"]');
        const { categorySlug } = linkNode.dataset;
        if (categorySlug && linkNode.getAttribute('aria-current')) {
            this.updateFilter();
        } else if (!linkNode.getAttribute('aria-current')) {
            this.updateFilter(categorySlug);
        }
    }

    handlePopState(event) {
        const { categorySlug } = event.state || {};
        this.updateFilter(categorySlug, false);
    }

    fetchResults(url) {
        this.ajaxTargetNode.setAttribute('aria-busy', 'true');
        if (this.controller) this.controller.abort();
        this.controller = new AbortController();
        const { signal } = this.controller;
        fetch(url, { ...FETCH_OPTS, signal })
            .then((response) => response.text())
            .then((html) => this.renderResults(html))
            .catch((err) => err.name !== 'AbortError' && console.error(err))
            .finally(() => {
                this.controller = null;
                this.ajaxTargetNode.setAttribute('aria-busy', 'false');
            });
    }

    renderResults(html) {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        const sourceNode = doc.querySelector(this.ajaxTarget);
        this.ajaxTargetNode.innerHTML = sourceNode.innerHTML;
        this.ajaxTargetNode.dispatchEvent(new CustomEvent('thishere:ajax:load', { bubbles: true }));
    }
}
