export default class ModalTrigger {
    static id = 'modal-trigger';

    constructor(node) {
        this.node = node;
        this.triggerNode = this.node.querySelector('[data-ref="modal-trigger"]');
        this.modalId = this.triggerNode.getAttribute('aria-controls');

        this.triggerNode.addEventListener('click', this.handleModalTriggerClick.bind(this));
        window.addEventListener('thishere:modal:closed', this.handleModalClosed.bind(this));
        window.addEventListener('thishere:modal:opened', this.handleModalOpened.bind(this));
    }

    handleModalClosed(event) {
        if (event?.detail?.id === this.modalId) {
            this.triggerNode.setAttribute('aria-expanded', 'false');
        }
    }

    handleModalOpened(event) {
        if (event?.detail?.id === this.modalId) {
            this.triggerNode.setAttribute('aria-expanded', 'true');
        }
    }

    handleModalTriggerClick() {
        this.node.dispatchEvent(
            new CustomEvent('thishere:modal:open', { bubbles: true, detail: { id: this.modalId } }),
        );
    }
}
