import { throttle } from 'throttle-debounce';

export default class Ticker {
    static id = 'ticker';

    constructor(node) {
        this.node = node;
        this.listNode = this.node.querySelector('[data-ref="list"]');
        this.observeResize();
    }

    handleViewportResize(viewportWidth) {
        // Clone the contents of the list node until it is at least twice the
        // width of the viewport, so that it can be translated left from 0 to
        // -50% to achieve the effect of seamless marquee animation

        // 1. Remove any previously cloned item nodes (marked as clones with
        // `aria-hidden`)
        this.listNode
            .querySelectorAll('[data-ref="item"][aria-hidden="true"]')
            .forEach((itemNode) => this.listNode.removeChild(itemNode));

        // 2. Iteratively clone the list items
        let itemMultiple = 1;
        do {
            this.listNode.querySelectorAll('[data-ref="item"]').forEach((itemNode) => {
                const clonedItemNode = itemNode.cloneNode(true);
                clonedItemNode.setAttribute('aria-hidden', 'true');
                this.listNode.append(clonedItemNode);
            });
            itemMultiple *= 2;

            // Make sure we don't end up in an infinite loop for some reason,
            // e.g. with very small or empty list items
            if (itemMultiple === 32) break;
        } while (this.listNode.getBoundingClientRect().width < viewportWidth * 2);

        // 3. Set a custom property to allow the component CSS to adjust the
        // animation speed (so that different length lists can appear to move
        // at a roughly similar speed)
        this.node.style.setProperty('--item-multiple', itemMultiple);
    }

    observeResize() {
        if ('ResizeObserver' in window) {
            new ResizeObserver(([entry]) => {
                const { inlineSize: viewportWidth } = entry.borderBoxSize[0];
                this.handleViewportResize(viewportWidth);
            }).observe(document.body);
        } else {
            window.addEventListener(
                'resize',
                throttle(100, () => {
                    const viewportWidth = document.body.getBoundingClientRect().width;
                    this.handleViewportResize(viewportWidth);
                }),
            );
            window.dispatchEvent(new Event('resize'));
        }
    }
}
