import { mobileLayout } from '@lib/media';

export default class TeamCard {
    static id = 'team-card';

    constructor(node) {
        this.node = node;
        this.toggleNode = this.node.querySelector('[data-ref="toggle"]');
        this.backPanelNode = this.node.querySelector('[data-ref="back"]');
        this.closeNode = this.node.querySelector('[data-ref="close"]');

        this.toggleNode.addEventListener('click', this.handleToggleClick.bind(this));
        this.closeNode.addEventListener('click', this.handleCloseClick.bind(this));
        window.addEventListener('thishere:team-card:opened', this.handleTeamCardOpened.bind(this));
    }

    handleCloseClick() {
        if (this.toggleNode.getAttribute('aria-expanded') === 'true') {
            this.handleToggleClick();
        }
    }

    handleTeamCardOpened(event) {
        if (
            !this.node.contains(event.target) &&
            this.toggleNode.getAttribute('aria-expanded') === 'true'
        ) {
            this.handleToggleClick();
        }
    }

    handleToggleClick() {
        const expanded = !(this.toggleNode.getAttribute('aria-expanded') === 'true');
        this.toggleNode.setAttribute('aria-expanded', expanded.toString());
        this.node.classList.toggle('is-expanded', expanded);

        if (expanded) {
            this.backPanelNode.removeAttribute('inert');
            this.closeNode.focus();
            if (mobileLayout.matches) {
                window.setTimeout(() => this.node.scrollIntoView(), 100);
            }
            this.node.dispatchEvent(
                new CustomEvent('thishere:team-card:opened', { bubbles: true }),
            );
        } else {
            this.backPanelNode.setAttribute('inert', 'true');
            this.toggleNode.focus();
        }
    }
}
