import { createFocusTrap } from 'focus-trap';
import Player from '@vimeo/player';

export default class VideoModal {
    static id = 'video-modal';

    constructor(node) {
        this.node = node;
        this.videoUrl = this.node.dataset.videoUrl;
        this.embedContainerNode = this.node.querySelector('[data-ref="embed"]');
        this.closeButtonNode = this.node.querySelector('[data-ref="close"]');
        this.mainNode = document.getElementById('main');

        this.onDocumentKeyUp = this.handleDocumentKeyUp.bind(this);
        window.addEventListener('thishere:modal:close', this.handleModalClose.bind(this));
        window.addEventListener('thishere:modal:open', this.handleModalOpen.bind(this));
        this.closeButtonNode.addEventListener('click', this.handleCloseButtonClick.bind(this));

        this.focusTrap = createFocusTrap(this.node);
        document.body.appendChild(this.node);
    }

    handleCloseButtonClick() {
        this.close();
    }

    handleDocumentKeyUp(event) {
        if (event.key === 'Escape') this.close();
    }

    handleModalClose(event) {
        if (event?.detail?.id === this.node.id) {
            this.close();
        }
    }

    handleModalOpen(event) {
        if (event?.detail?.id === this.node.id) {
            this.open();
        }
    }

    close() {
        this.pause();
        this.node.setAttribute('hidden', true);
        document.removeEventListener('keyup', this.onDocumentKeyUp);
        document.body.classList.remove('has-open-modal');
        if (this.mainNode) this.mainNode.removeAttribute('aria-hidden', true);
        this.node.dispatchEvent(
            new CustomEvent('thishere:modal:closed', {
                bubbles: true,
                detail: { id: this.node.id },
            }),
        );
        this.focusTrap.deactivate();
    }

    open() {
        this.node.removeAttribute('hidden');
        document.addEventListener('keyup', this.onDocumentKeyUp);
        document.body.classList.add('has-open-modal');
        if (this.mainNode) this.mainNode.setAttribute('aria-hidden', true);
        this.node.dispatchEvent(
            new CustomEvent('thishere:modal:opened', {
                bubbles: true,
                detail: { id: this.node.id },
            }),
        );
        this.focusTrap.activate();
        this.play();
    }

    pause() {
        this.player && this.player.pause();
    }

    play() {
        if (this.player) return;

        this.player = new Player(this.embedContainerNode, {
            url: this.videoUrl,
            responsive: true,
            dnt: true,
        });

        this.player.ready().then(() => {
            const node = this.player.element;

            // Convert Vimeo's padding hack on parent element to
            // `aspect-ratio`
            const paddingTop = node.parentNode.style.paddingTop;
            const aspectRatio = 100 / Number(paddingTop.replace('%', ''));
            node.parentNode.style.removeProperty('padding');
            node.parentNode.style.aspectRatio = aspectRatio;
        });
    }
}
