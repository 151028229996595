const context = require.context('@components', true, /\.js$/);
const components = {};
context.keys().forEach((key) => {
    const module = context(key).default;
    if (module.id) components[module.id] = module;
});

const initComponents = (components, rootNode = document.body) => {
    rootNode.querySelectorAll('[data-component]').forEach((node) => {
        node.dataset.component
            .split(/\s+/)
            .filter((componentId) => componentId in components)
            .map((componentId) => new components[componentId](node));
    });
};
initComponents(components);

// Listen for our content load event to rebind component behaviour
window.addEventListener('thishere:ajax:load', (event) => {
    console.log('Binding components in loaded content', event.target);
    initComponents(components, event.target);
});
