export default class MediaGridCard {
    static id = 'media-grid-card';

    constructor(node) {
        this.node = node;
        this.videoNode = this.node.querySelector('[data-ref="video"]');

        this.videoNode.addEventListener('play', this.handleVideoPlay.bind(this));
        window.addEventListener(
            'thishere:media-grid-card:play',
            this.handleVideoPlayEvent.bind(this),
        );
    }

    handleVideoPlay() {
        this.node.dispatchEvent(
            new CustomEvent('thishere:media-grid-card:play', { bubbles: true }),
        );
    }

    handleVideoPlayEvent() {
        if (!this.node.contains(event.target) && !this.videoNode.paused) {
            this.videoNode.pause();
        }
    }
}
