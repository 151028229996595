import { createFocusTrap } from 'focus-trap';

export default class Nav {
    static id = 'nav';

    constructor(node) {
        this.node = node;
        this.mainNode = document.getElementById('main');

        this.onDocumentClick = this.handleDocumentClick.bind(this);
        this.onDocumentKeyUp = this.handleDocumentKeyUp.bind(this);
        window.addEventListener('thishere:nav:close', this.close.bind(this));
        window.addEventListener('thishere:nav:open', this.open.bind(this));
        this.node.addEventListener('click', this.handleClick.bind(this));

        this.focusTrap = createFocusTrap(this.node, {
            allowOutsideClick: true,
        });
    }

    handleClick(event) {
        if (event.target.closest('[data-ref="close"], [data-ref="link"]')) {
            this.close();
        }
    }

    handleDocumentClick(event) {
        if (!this.node.contains(event.target)) {
            this.close();
        }
    }

    handleDocumentKeyUp(event) {
        if (event.key === 'Escape') this.close();
    }

    close() {
        this.node.setAttribute('hidden', true);
        document.removeEventListener('keyup', this.onDocumentKeyUp);
        document.removeEventListener('click', this.onDocumentClick);
        document.body.classList.remove('has-open-nav');
        if (this.mainNode) this.mainNode.removeAttribute('aria-hidden', true);
        this.node.dispatchEvent(new CustomEvent('thishere:nav:closed', { bubbles: true }));
        this.focusTrap.deactivate();
    }

    open() {
        this.node.removeAttribute('hidden');
        document.addEventListener('keyup', this.onDocumentKeyUp);
        window.setTimeout(() => document.addEventListener('click', this.onDocumentClick), 0);
        document.body.classList.add('has-open-nav');
        if (this.mainNode) this.mainNode.setAttribute('aria-hidden', true);
        this.node.dispatchEvent(new CustomEvent('thishere:nav:opened', { bubbles: true }));
        this.focusTrap.activate();
    }
}
