export default class LazyVideo {
    static id = 'lazy-video';

    constructor(node) {
        this.node = node;
        this.setupIntersectionObserver();
    }

    activateSources() {
        this.node.querySelectorAll('source').forEach((sourceNode) => {
            sourceNode.setAttribute('src', sourceNode.dataset.src);
            sourceNode.removeAttribute('data-src');
            this.node.load();
        });
    }

    setupIntersectionObserver() {
        if (!('IntersectionObserver' in window)) {
            this.activateSources();
            return;
        }

        const handleIntersection = ([entry], observer) => {
            if (entry.isIntersecting) {
                this.activateSources();
                observer.disconnect();
            }
        };

        const intersectionOptions = {
            threshold: [0, 1],
            root: document,
            rootMargin: '-100px 0px -100px 0px',
        };

        new IntersectionObserver(handleIntersection, intersectionOptions).observe(this.node);
    }
}
